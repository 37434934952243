import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

import { Dates } from "../Interfaces/Dates";

type Props = {
  date: Dates;
};

function Date({ date }: Props) {
  const myDate = [];
  if (date?.valid && Object.keys(date.valid).length) {
    myDate.push(
      date.valid.day.toString().padStart(2, "0") +
        "." +
        date.valid.month.toString().padStart(2, "0") +
        "." +
        date.valid.year
    );
  }

  if (date?.expire && Object.keys(date.expire).length) {
    myDate.push(
      date.expire.day.toString().padStart(2, "0") +
        "." +
        date.expire.month.toString().padStart(2, "0") +
        "." +
        date.expire.year
    );
  }

  let myDateStr = null;
  if (myDate.length > 0) {
    myDateStr = (
      <p className="text-sm text-gray-600 flex items-center">
        <FontAwesomeIcon icon={faClock} />
        &nbsp;
        {myDate.join(" - ")}
      </p>
    );
  }

  return <div>{myDateStr}</div>;
}

export default Date;
