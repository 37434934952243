import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { Article } from "../Interfaces/Article";
import Date from "../Components/Date";
import Image from "../Components/Image";

type Props = {
  article: Article;
};

function ArticleCard({ article }: Props) {
  let subtitle = null;
  if (article.subtitle !== "") {
    subtitle = (
      <div className="text-gray-900 font-medium text-base mb-2">
        {article.subtitle}
      </div>
    );
  }
  let intro = null;
  if (article.intro !== "") {
    intro = (
      <div
        className="text-gray-700 text-base"
        dangerouslySetInnerHTML={{ __html: article.intro }}
      ></div>
    );
  }

  // Image
  let image = (
    <div className="m-3 h-medium w-medium flex-none bg-gray-200 bg-cover rounded text-center overflow-hidden"></div>
  );

  if (article.image && article.image.id > 0) {
    image = (
      <div className="m-3 h-medium w-medium flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden">
        <Image image={article.image} size="medium" />
      </div>
    );
  }

  // Button
  let buttonReadMore = <div></div>;
  if (article.has_content) {
    buttonReadMore = (
      <div className="py-4">
        <Link
          to={`/article/${article.slug_name}`}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
        >
          Weiterlesen &nbsp;
          <FontAwesomeIcon icon={faArrowRight} />
        </Link>
      </div>
    );
  }

  // Render
  return (
    <div className="max-w-sm w-full lg:max-w-full lg:flex border border-gray-400 rounded">
      {image}
      <div className="m-3 w-full bg-white p-4 flex flex-col justify-between leading-normal">
        <div className="mb-1">
          <Date date={article?.date} />
          <div className="text-gray-900 font-bold text-xl mb-2">
            {article.title}
          </div>
          {subtitle}
          {intro}
          {buttonReadMore}
        </div>
      </div>
    </div>
  );
}

export default ArticleCard;
