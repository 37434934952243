import React from "react";

import AuthService from "../Services/Auth";

function Logout() {
  AuthService.logout();

  return (
    <div>
      <div className="container mx-auto h-full flex justify-center items-center">
        <div className="w-1/3">
          <h1 className="text-gray-900 font-bold text-xl mb-2 text-center">
            Logout
          </h1>
          <div className=" p-8 border-t-12 bg-gray-400 mb-6 rounded-lg shadow-lg">
            <div className="mb-4 text-center">Erfolgreich ausgeloggt.</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Logout;
