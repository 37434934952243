import Axios from "axios";

import ApiUrl from "../Libraries/ApiUrl";
import { ArticleDetails, ArticleList } from "../Interfaces/Article";

import AuthService from "../Services/Auth";

class ArticleService {
  getDetails(slugName: string) {
    const apiURL = ApiUrl();
    const url = `${apiURL}/article/${slugName}`;
    return Axios.get<ArticleDetails>(url, {
      headers: AuthService.getHeader(),
    }).then((response) => {
      return response.data;
    });
  }
  getList(slugName: string) {
    const apiURL = ApiUrl();
    const url = `${apiURL}/articles/${slugName}`;
    return Axios.get<ArticleList>(url, {
      headers: AuthService.getHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new ArticleService();
