import React from "react";

type Props = {
  code: number;
};

function Alert({ code }: Props) {
  let content = null;

  if (code > 0) {
    content = (
      <div role="alert" className="m-3">
        <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
          Danger
        </div>
        <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
          <p>Something not ideal might be happening.</p>
          <p>{getCode(code)}</p>
        </div>
      </div>
    );
  }

  return content;
}

function getCode(code: number) {
  // TODO: i18n
  switch (code) {
    case 401:
      return "Unauthorized!";
    case 403:
      return "Forbidden!";
    case 404:
      return "Not found!";
    default:
      return "unknown error";
  }
}

export default Alert;
