import React, { useState } from "react";

import Alert from "../Components/Alert";
import Loader from "../Components/Loader";
import AuthService from "../Services/Auth";
import { Redirect } from "react-router-dom";

function Login() {
  const [state, setState] = useState({
    username: "",
    password: "",
    loading: false,
    errorCode: 0,
    redirect: false,
  });

  const handleLogin = function () {
    if (state.username !== "" && state.password !== "") {
      setState((prevState) => {
        return { ...prevState, loading: true, errorCode: 0 };
      });
      AuthService.login(state.username, state.password)
        .then(() => {
          setState((prevState) => {
            return {
              ...prevState,
              loading: false,
              errorCode: 0,
              redirect: true,
            };
          });
        })
        .catch((error) => {
          // console.log(error.response);
          setState((prevState) => {
            return {
              ...prevState,
              loading: false,
              errorCode: error?.response?.status | 1,
            };
          });
        });
    }
  };

  const onChangePassword = function (name: string) {
    setState((prevState) => {
      return { ...prevState, password: name };
    });
  };

  const onChangeUsername = function (name: string) {
    setState((prevState) => {
      return { ...prevState, username: name };
    });
  };

  if (state.redirect) {
    return <Redirect to="/home" />;
  }

  return (
    <div>
      <div className="container mx-auto h-full flex justify-center items-center">
        <div className="w-1/3">
          <h1 className="text-gray-900 font-bold text-xl mb-2 text-center">
            Login
          </h1>
          <div className=" p-8 border-t-12 bg-gray-400 mb-6 rounded-lg shadow-lg">
            <div className="mb-4">
              <label className="font-bold text-grey-900 block mb-2">
                Benutzername oder E-Mail
              </label>
              <input
                type="text"
                className="block appearance-none w-full bg-white border border-grey-light hover:border-grey-800 px-2 py-2 rounded shadow"
                placeholder="Dein Benutzername"
                onChange={(e) => onChangeUsername(e.target.value)}
                disabled={state.loading}
              />
            </div>
            <div className="mb-4">
              <label className="font-bold text-grey-900 block mb-2">
                Passwort
              </label>
              <input
                type="password"
                className="block appearance-none w-full bg-white border border-grey-light hover:border-grey-800 px-2 py-2 rounded shadow"
                placeholder="Dein Passwort"
                onChange={(e) => onChangePassword(e.target.value)}
                disabled={state.loading}
              />
            </div>
            <div className="mb-4">
              <button
                onClick={handleLogin}
                className="text-gray-900 hover:text-blue-800 bg-gray-300 rounded p-3"
              >
                Login
              </button>
              <Loader state={state.loading} />
              <Alert code={state.errorCode} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
