import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTransition, animated } from "react-spring";

import Alert from "../Components/Alert";
import Loader from "../Components/Loader";
import ArticleImage from "../Components/ArticleImage";
import Date from "../Components/Date";
import Image from "../Components/Image";

import { ArticleDetails, ArticleImage as AI } from "../Interfaces/Article";

import ArticleService from "../Services/Article";

function Article() {
  const [show, setShow] = useState(false);
  const transitionImageMask = useTransition(show, null, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  const transitionImage = useTransition(show, null, {
    from: { opacity: 0, transform: "scale(0,0)" },
    enter: { opacity: 1, transform: "scale(1,1)" },
    leave: { opacity: 0, transform: "scale(0,0)" },
  });

  let { slugName } = useParams();
  if (!slugName) {
    slugName = "home";
  }

  let content = null;
  const [article, setArticle] = useState<ArticleDetails | null>(null);
  const [image, setImage] = useState<AI | null>(null);
  const [errorCode, setErrorCode] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    ArticleService.getDetails(slugName)
      .then((response) => {
        setArticle(response);
        setLoading(false);
        setErrorCode(0);
      })
      .catch((error) => {
        setArticle(null);
        setLoading(false);
        setErrorCode(error?.response?.status | 1);
      });
  }, [slugName]);

  const nextImage = function (direction: string = "") {
    if (article && image) {
      let idx = 0;
      for (let x = 0; x < article.files.gallery.length; x++) {
        if (article.files.gallery[x].id === image.id) {
          if (direction === "next") {
            idx = x + 1 === article.files.gallery.length ? 0 : x + 1;
          } else {
            idx = x - 1 === -1 ? article.files.gallery.length - 1 : x - 1;
          }
        }
        setImage(article.files.gallery[idx]);
      }
    }
  };

  if (article) {
    let gallery = null;
    if (article.files.gallery.length > 0) {
      const images = article.files.gallery.map((imageItem: AI) => {
        return (
          <div
            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 px-2"
            key={imageItem.id}
          >
            <div
              className="mb-4 bg-gray-400 h-medium w-medium items-center cursor-pointer"
              onClick={() => {
                setShow(true);
                setImage(imageItem);
                window.scrollTo(0, 0);
              }}
            >
              <Image image={imageItem} size="medium" />
            </div>
          </div>
        );
      });
      gallery = (
        <div className="mt-3 p-3 border rounded">
          <div className="flex flex-wrap -mb-4 -mx-2">{images}</div>
        </div>
      );
    }

    content = (
      <div className="p-3">
        <div className="p-3 border rounded">
          <Date date={article.date} />
          <h1 className="text-gray-900 font-bold text-xl mb-2">
            {article.title}
          </h1>
          <h2 className="text-gray-900 font-medium text-lg mb-2">
            {article.subtitle}
          </h2>
          <h3
            className="text-gray-900 font-medium text-base mb-2"
            dangerouslySetInnerHTML={{ __html: article.intro }}
          ></h3>
          <span
            className="text-gray-900 font-normal text-base mb-2"
            dangerouslySetInnerHTML={{ __html: article.body }}
          ></span>

          <div
            className="text-gray-900 font-medium text-base mt-2"
            dangerouslySetInnerHTML={{ __html: article.conclusion }}
          ></div>
        </div>
        {gallery}
      </div>
    );
  }

  return (
    <div>
      <Loader state={loading} />
      <Alert code={errorCode} />
      {transitionImageMask.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              style={props}
              className="bg-black-t-50 fixed top-0 left-0 w-full h-full z-50"
              onClick={() => setShow(false)}
            >
              {" "}
            </animated.div>
          )
      )}
      {transitionImage.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              style={props}
              className="absolute bg-white w-800 rounded-lg border shadow z-50 ml-10"
            >
              <div className="flex bg-gray-200 items-center justify-center rounded-lg">
                <ArticleImage
                  nextImage={nextImage}
                  closeImage={() => setShow(false)}
                  image={image}
                />
              </div>
            </animated.div>
          )
      )}
      {content}
    </div>
  );
}

export default Article;
