import Axios from "axios";
import { BehaviorSubject } from "rxjs";

import { Jwt } from "../Interfaces/Jwt";
import ApiUrl from "../Libraries/ApiUrl";

const currentUserSubject = new BehaviorSubject(localStorage.getItem("user"));
// const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
export const authenticationService = {
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    if (currentUserSubject.value) {
      const jwt: Jwt = JSON.parse(atob(currentUserSubject.value.split(".")[1]));
      return jwt;
    }
    return currentUserSubject.value;
  },
};

class AuthService {
  getCurrentUser() {
    let userData = localStorage.getItem("user");
    if (!userData) {
      return "";
    }

    // console.log(userData);
    const jwt: Jwt = JSON.parse(atob(userData.split(".")[1]));
    // console.log(jwt);

    // if (this.isExpired(jwt.exp) || this.getExpirationDate(jwt.exp)) {
    //   this.logout();
    //   return "";
    // }

    return jwt;
  }

  getExpirationDate(exp?: number) {
    return (exp && exp * 1000) || null;
  }

  getHeader() {
    let userData = localStorage.getItem("user");
    if (userData) {
      return { Authorization: "Bearer " + userData };
    } else {
      return {};
    }
  }

  isExpired(exp?: number) {
    if (!exp) {
      return false;
    }

    return Date.now() > exp;
  }

  login(username: string, password: string) {
    const apiURL = ApiUrl();
    return Axios.post(apiURL + "/auth", {
      username: username,
      password: password,
    }).then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.token));
        currentUserSubject.next(response.data.token);
      }

      return response.data;
    });
  }

  logout() {
    localStorage.removeItem("user");
    currentUserSubject.next(null);
  }
}

export default new AuthService();
