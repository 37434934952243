import React from "react";

type Props = {
  state: boolean;
};

function Loader({ state }: Props) {
  let content = null;

  if (state === true) {
    content = (
      <div className="flex justify-center">
        <div className="loader"></div>
      </div>
    );
  }

  return content;
}

export default Loader;
