function ApiUrl() {
  let apiURL = `${process.env.REACT_APP_BASE_URL}/`;
  let host = "";
  if (typeof window !== "undefined") {
    host = window.location.hostname;

    if (window.location.hostname === "localhost") {
      host = `${process.env.REACT_APP_LOCAL_BASE_HOST}`;
    } else {
      const urlSplit = host.split(".");
      if (urlSplit[0] === "www") {
        urlSplit[0] = "api";
        host = urlSplit.join(".");
      }
    }

    apiURL = window.location.protocol + "//" + host + "/v1";
  }
  return apiURL;
}
export default ApiUrl;
