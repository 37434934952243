import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Alert from "./Alert";
import Loader from "./Loader";

import { Navigation as iNav } from "../Interfaces/Navigation";

import ApiUrl from "../Libraries/ApiUrl";

import AuthService, { authenticationService } from "../Services/Auth";
import { IconName } from "@fortawesome/fontawesome-svg-core";

type Props = {
  name: string;
  linkClasses: string;
  flex: string | undefined;
};

function Navigation({ name, linkClasses, flex }: Props) {
  let content = null;

  const apiURL = ApiUrl();
  const url = `${apiURL}/navigation/${name}`;
  const [navigation, setNavigation] = useState<iNav[] | undefined>(undefined);
  const [errorCode, setErrorCode] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    authenticationService.currentUser.subscribe((x) => {
      // console.log(x);
      if (x) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  });

  useEffect(() => {
    setLoading(true);
    Axios.get<iNav[]>(url)
      .then((response) => {
        setNavigation(response.data);
        setLoading(false);
        setErrorCode(0);
      })
      .catch((error) => {
        setLoading(false);
        setErrorCode(error?.response?.status | 1);
      });
  }, [url]);

  const checkGroups = function (secGroups: string[] | null) {
    if (!secGroups) {
      return true;
    }
    const currentUser = AuthService.getCurrentUser();
    if (currentUser) {
      return secGroups.some((e: string) => {
        const idx = currentUser.secGroups.indexOf(e);
        if (idx >= 0) {
          return true;
        }
        return false;
      });
    }
    return false;
  };

  if (navigation) {
    // TODO: Links sind ggf. keine direkten links, sondern nur Parents
    content = navigation.map((navItem: iNav) => {
      if (
        navItem.hide_on_login_state === 0 ||
        (isLoggedIn === false && navItem.hide_on_login_state === -1) ||
        (isLoggedIn === true && navItem.hide_on_login_state === 1)
      ) {
        if (checkGroups(navItem.sec_groups)) {
          return (
            <li key={navItem.slug_name} className="mr-6">
              <Link to={`/${navItem.slug_name}`} className={linkClasses}>
                {navItem.name_icon ? (
                  <span>
                    <FontAwesomeIcon icon={navItem.name_icon as IconName} />
                    &nbsp;
                  </span>
                ) : null}

                {navItem.name}
              </Link>
            </li>
          );
        }
        return null;
      }
      return null;
    });
  }

  return (
    <div>
      <Loader state={loading} />
      <Alert code={errorCode} />
      <ul className={flex}>{content}</ul>
    </div>
  );
}

export default Navigation;
