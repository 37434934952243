import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Navigation from "./Components/Navigation";
import Article from "./Views/Article";
import Articles from "./Views/Articles";
import Login from "./Views/Login";
import Logout from "./Views/Logout";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faLock,
  faSignInAlt,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";

function App() {
  library.add(faHome, faLock, faSignInAlt, faSignOutAlt);
  return (
    <div className="relative pb-10 min-h-screen">
      <Router>
        <Header />

        <div className="flex mb-4 p-3">
          <div className="w-3/4">
            <Switch>
              <Route exact path="/">
                <Articles />
              </Route>
              <Route path="/article/:slugName">
                <Article />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/logout">
                <Logout />
              </Route>
              <Route path="/:slugName">
                <Articles />
              </Route>
            </Switch>
          </div>
          <div className="w-1/4">
            <div className="mt-3 p-3 border rounded">
              <Navigation
                name="service"
                linkClasses="text-gray-900 py-3 border-t block"
                flex=""
              />
            </div>
          </div>
        </div>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
