import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";

import { ArticleImage as AI } from "../Interfaces/Article";

import Image from "../Components/Image";

type Props = {
  closeImage: () => void;
  nextImage: (direction: string) => void;
  image: AI | null;
};

function ArticleImage({ closeImage, nextImage, image }: Props) {
  return (
    <div>
      {/* NavElements */}
      <div
        className="fixed top-0 right-0 mr-3 z-50 cursor-pointer"
        onClick={closeImage}
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </div>

      <div className="fixed top-0 left-0 h-full">
        <div className="flex h-full inline-block">
          <div
            className="flex items-center justify-center cursor-pointer"
            onClick={() => nextImage("prev")}
          >
            <FontAwesomeIcon size="3x" icon={faChevronCircleLeft} />
          </div>
        </div>
      </div>

      <div className="fixed top-0 right-0 h-full">
        <div className="flex h-full inline-block">
          <div className="flex items-center justify-center cursor-pointer">
            <FontAwesomeIcon
              size="3x"
              icon={faChevronCircleRight}
              onClick={() => nextImage("next")}
            />
          </div>
        </div>
      </div>

      {/* Image Data */}
      <h1 className="text-gray-900 font-bold text-xl mb-2">{image?.title}</h1>
      <h2 className="text-gray-900 font-medium text-lg mb-2">
        {image?.subtitle}
      </h2>

      <div className="mb-4 h-xlarge w-xlarge items-center">
        <Image image={image} size="xlarge" />
      </div>
    </div>
  );
}

export default ArticleImage;
