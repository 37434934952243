import React, { useState } from "react";

import { ArticleImage as AI } from "../Interfaces/Article";

import Loader from "../Components/Loader";

import ApiUrl from "../Libraries/ApiUrl";

type Props = {
  image: AI | null;
  size: string;
};

function Image({ image, size }: Props) {
  const [loading, setLoading] = useState(true);
  const [id, setID] = useState(0);

  const handleImageLoaded = function () {
    setLoading(false);
    if (image) setID(image?.id);
  };

  let content = <div></div>;

  if (image) {
    const apiURL = ApiUrl();
    const imageURL = `${apiURL}/file/${image?.id}/${size}`;
    if (id !== image.id && loading === false) {
      setLoading(true);
    }
    content = (
      <img
        className="rounded"
        src={imageURL}
        alt={image?.title}
        onLoad={() => handleImageLoaded()}
      />
    );
  }

  let loader = <div></div>;
  if (loading === true) {
    loader = (
      <div className={`absolute h-${size} w-${size}`}>
        <div className={`flex h-${size}`}>
          <div className={`flex w-${size} items-center justify-center`}>
            <Loader state={loading} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {loader}
      {content}
    </div>
  );
}

export default Image;
