import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";

import Loader from "./Loader";
import Navigation from "./Navigation";

import Alert from "../Components/Alert";

import ApiUrl from "../Libraries/ApiUrl";

import { Base } from "../Interfaces/Base";
import { Jwt } from "../Interfaces/Jwt";

import AuthService, { authenticationService } from "../Services/Auth";

interface IMyComponentState {
  currentUser: Jwt | null;
  loading: boolean;
  meta: Base | undefined;
  errorCode: number;
  width: number;
  height: number;
}
class Header extends Component<{}, IMyComponentState> {
  // currentUser: Jwt;
  constructor(props: any) {
    super(props);

    this.state = {
      currentUser: null,
      loading: false,
      meta: undefined,
      errorCode: 0,
      width: 0,
      height: 0,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    authenticationService.currentUser.subscribe(() => {
      const user = AuthService.getCurrentUser();
      if (user !== "") {
        this.setState((prevState) => {
          return {
            ...prevState,
            currentUser: user,
          };
        });
      } else {
        this.setState((prevState) => {
          return {
            ...prevState,
            currentUser: null,
          };
        });
      }
    });

    const url = ApiUrl();
    Axios.get<Base>(url)
      .then((response) => {
        this.setState((prevState) => {
          return {
            ...prevState,
            loading: false,
            meta: response.data,
          };
        });
      })
      .catch((error) => {
        // console.log(error.response);
        this.setState((prevState) => {
          return {
            ...prevState,
            loading: false,
            errorCode: error.response.status,
          };
        });
      });

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    let styleHeader = {};
    if (this.state.meta) {
      let imageSize = "large";

      if (this.state.width > 1400) {
        imageSize = "xlarge";
      }

      const url = ApiUrl();
      const urlTitleImage = `${url}/file/${this.state.meta?.site.title_image_id}/banner_medium_${imageSize}`;
      styleHeader = {
        backgroundImage: `url(${urlTitleImage})`,
      };
    }

    return (
      <header
        className="border-b bg-no-repeat h-40 p-3 flex justify-between items-center"
        style={styleHeader}
      >
        <Loader state={this.state.loading} />
        <Alert code={this.state.errorCode} />
        <Navigation
          name="main"
          linkClasses="text-gray-900 hover:text-blue-800 bg-gray-300 opacity-75 rounded p-3"
          flex="flex"
        />
        <Link
          className="bg-gray-400 opacity-75 rounded pl-4 pr-4 text-4xl text-gray-800 font-bold font-mono"
          to="/home"
        >
          {this.state.meta?.site.title}
        </Link>
        {this.state.currentUser ? (
          <div className="text-gray-900 font-semibold bg-gray-300 opacity-25 rounded p-3">
            Eingeloggt als: <br />
            {this.state?.currentUser?.sub}
          </div>
        ) : (
          <div></div>
        )}
      </header>
    );
  }

  updateWindowDimensions() {
    this.setState((prevState) => {
      return {
        ...prevState,
        width: window.innerWidth,
        height: window.innerHeight,
      };
    });
  }
}

export default Header;
