import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Alert from "../Components/Alert";
import ArticleCard from "../Components/ArticleCard";
import Loader from "../Components/Loader";

import ArticleService from "../Services/Article";

import { Article, ArticleList } from "../Interfaces/Article";

function Articles() {
  let { slugName } = useParams();
  if (!slugName) {
    slugName = "home";
  }

  let content = null;
  const [list, setList] = useState<ArticleList | null>(null);
  const [errorCode, setErrorCode] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    ArticleService.getList(slugName)
      .then((response) => {
        setList(response);
        setLoading(false);
        setErrorCode(0);
      })
      .catch((error) => {
        setList(null);
        setLoading(false);
        setErrorCode(error?.response?.status | 1);
      });
  }, [slugName]);

  if (list) {
    content = list.articles.map((article: Article) => (
      <div className="p-3" key={article.slug_name}>
        <ArticleCard article={article} />
      </div>
    ));
  }

  return (
    <div>
      <Loader state={loading} />
      <Alert code={errorCode} />
      <h1 className="text-gray-900 font-bold text-xl mb-2 pl-3">
        {list?.title}
      </h1>
      <h2 className="text-gray-900 font-medium text-lg mb-2 pl-3">
        {list?.subtitle}
      </h2>
      {content}
    </div>
  );
}

export default Articles;
